export default theme => ({
  root: {
    display: 'inline-block',
    verticalAlign: 'middle',
    position: 'relative',
    background: theme.palette.border,
    overflow: 'hidden',
  },
  initials: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: '2.1em',
    color: theme.palette.gray70,
  },
  imageContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
  image: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '10%',
  },
  isSquare: {
    borderRadius: 0,
  },
  hasBorder: {
    border: `1px solid ${theme.palette.border}`,
  },
  size_sm: {
    width: 30,
    height: 30,
    '& $initials': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  size_md: {
    width: 40,
    height: 40,
    '& $initials': {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  size_ld: {
    width: 72,
    height: 72,
    '& $initials': {
      fontSize: theme.typography.pxToRem(30),
    },
  },
  size_xl: {
    width: 149,
    height: 149,
    '& $initials': {
      fontSize: theme.typography.pxToRem(50),
    },
  },
  size_50: {
    width: 50,
    height: 50,
  },
});
